import logo from './rewa.jpg';
import './App.css';

function App() {
  return (
    <div style={{backgroundColor:"#fed219", height:"100vh", display:"flex", alignItems:"center"}} >
        <img src={logo} width='100%'  alt="logo" />
    </div>
  );
}

export default App;
